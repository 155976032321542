import { RequestPagination } from "./types/api-types";

export const DATE_FORMAT: string = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT: string = 'YYYY-MM-DD HH:mm:ss'

export const DEFAULT_PAGINATION: RequestPagination = {
  paginate: 1,
  page: 1,
  per_page: 15,
}
